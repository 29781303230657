<template>
  <main>
    <trac-loading v-if="loading"></trac-loading>

    <div v-else>
      <div class="">
        <button class="bg-gray-800 py-2 px-3 rounded-md text-xs text-white mt-2" @click="$router.go(-1)">
          &laquo; Back
        </button>
      </div>
      <h1 class="mt-4 text-lg font-bold">Create New Loan</h1>
      <p class=" text-xs">Enter details below</p>
      <form class="max-w-6xl" @submit.prevent="submit">
        <div class="grid grid-cols-2 gap-10 mt-4">
          <div v-for="(data, index) in formatLoanData" :key="index">
            <div class="mb-3 font-medium">{{ data.name }}</div>
            <div v-if="data.type === 'text' ||
              data.type === 'number' ||
              data.type === 'date' ||
              data.type === 'email'
              ">
              <input @keydown="onKeyDown" :required="data.required" :type="data.type"
                class="py-3 text-sm leading-8 w-full text-gray-900 font-medium placeholder:text-gray-three pl-10 pr-4 block shadow-sm focus:ring-indigo-500 focus:border-indigo-500 border border-gray-300 rounded-md"
                v-model="form[data.payload]" />
            </div>
            <div v-if="data.type === 'select'">
              <select
                class="py-5 px-6 leading-8 w-full text-gray-900 font-medium placeholder:text-gray-three pl-10 pr-10 block shadow-sm focus:ring-indigo-500 focus:border-indigo-500 border border-gray-300 rounded-md"
                v-model="form[data.payload]" :required="data.required">
                <option v-for="(option, index) in data.option" :value="option.value" :key="index">
                  {{ option.label }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="flex justify-center items-center mt-10">
          <button :disabled="isLoading" :class="isLoading ? 'bg-gray-500 cursor-not-allowed' : 'bg-blue-900'
            " type="submit"
            class="' w-64 relative flex items-center justify-center px-12 py-4 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 ',">
            Submit
          </button>
        </div>
      </form>
    </div>
  </main>
</template>

<script>
import moment from "moment";
import {
  GET_USER_BUSINESS_ID,
  GET_USER_DATA,
} from "../browser-db-config/localStorage";

export default {
  data() {
    return {
      moment,
      loading: false,
      isLoading: false,
      disbursementOption: [],
      loanStatusOption: [],
      loanTypeOption: [],
      loanRepaymentOption: [],
      distribution_channel: [],
      loanData: [
        {
          name: "Borrower Full Name",
          type: "text",
          required: true,
          payload: "borrower_name",
        },
        {
          name: "Borrower Phone Number",
          type: "text",
          required: true,
          payload: "borrower_phone",
        },
        {
          name: " Date of Birth",
          type: "date",
          payload: "dob",
          placeholder: 'dd-mm-yyyy'

        },
        {
          name: "Is Business Registered",
          type: "select",
          required: true,
          payload: "is_business_registered",
          option: [
            {
              label: "Yes",
              value: true,
            },
            {
              label: "No",
              value: false,
            },
          ],
        },
        {
          name: "BVN",
          type: "text",
          payload: "bvn",
          shouldShow: false,

        },
        {
          name: "Store ",
          type: "select",
          payload: "store_id",
          option: [],
        },
        {
          name: "Loan Amount",
          type: "number",
          required: true,
          payload: "loan_amount",
        },
        {
          name: "Loan Type",
          type: "select",
          required: true,
          payload: "loan_type_id",
          option: [],
        },
        {
          name: "Loan Status",
          type: "select",
          required: true,
          payload: "loan_status_id",
          option: [],
        },
        {
          name: "Interest Rate",
          type: "number",
          payload: "interest_rate",
          required: true,
        },
        {
          name: "Tenure",
          type: "number",
          payload: "tenor",
          required: true,
        },
        {
          name: "Rc Number",
          type: "number",
          payload: "rc_number",
          required: true,
          shouldShow: false,
        },
        {
          name: "Repayment Method",
          type: "select",
          required: true,
          payload: "repayment_method_id",
          option: [],
        },
        {
          name: "Disbursement Method",
          type: "select",
          required: true,
          payload: "disbursement_method_id",
          option: [],
        },
        {
          name: "Distribution Channel",
          type: "select",
          required: true,
          payload: "distribution_channel",
          option: [],
        },
        {
          name: "Distribution Date",
          type: "date",
          payload: "distribution_date",
          required: true,
          placeholder: 'dd-mm-yyyy'
        },
        {
          name: " Date of Incorporation",
          type: "date",
          payload: "date_of_incorporation",
          required: true,
          placeholder: 'dd-mm-yyyy',
          shouldShow: false,


        },
        {
          name: "Referring Officer",
          type: "email",
          payload: "referring_officer",
        },
        // {
        //   name: "Is Business Registered",
        //   type: "select",
        //   required: true,
        //   payload: "is_business_registered",
        //   option: [
        //     {
        //       label: "Yes",
        //       value: true,
        //     },
        //     {
        //       label: "No",
        //       value: false,
        //     },
        //   ],
        // },
      ],
      form: {
        loan_amount: "",
        loan_type_id: "",
        interest_rate: 0,
        tenor: 0,
        repayment_method_id: "",
        disbursement_method_id: "",
        referring_officer: "",
        loan_status_id: "",
        borrower_phone: "",
        borrower_name: "",
        store_id: "",
        distribution_channel: "",
        distribution_date: "",
        is_business_registered: false,
        rc_number: "",
        dob: "",
        bvn: "",
        date_of_incorporation: "",
      },
    };
  },
  watch: {
    'form.is_business_registered': {
      handler: function (val) {
        if (val) {
          this.loanData.forEach((item) => {
            if (item.payload === 'rc_number') {
              item.shouldShow = true
            }
            if (item.payload === 'date_of_incorporation') {
              item.shouldShow = true
            }
            if (item.payload === 'bvn') {
              item.shouldShow = true
            }
          })
        } else {
          this.loanData.forEach((item) => {
            if (item.payload === 'rc_number') {
              item.shouldShow = false
            }
            if (item.payload === 'date_of_incorporation') {
              item.shouldShow = false
            }
            if (item.payload === 'bvn') {
              item.shouldShow = false
            }
          })
        }
      },
      deep: true
    }
  },
  computed: {
    formatLoanData() {
      return this.loanData.filter((item) => item.shouldShow !== false)
    }
  },
  methods: {
    onKeyDown(event) {
      if (event.key === '-') {
        event.preventDefault();
      }
    },
    async makeRequest() {
      const allDisbursement = async () => {
        return await this.$store.dispatch("GET_ALL_DISBURSEMENT_METHODS");
      };
      const allLoanStatus = async () => {
        return await this.$store.dispatch("GET_ALL_LOAN_STATUS");
      };
      const allLoanTypes = async () => {
        return await this.$store.dispatch("GET_ALL_LOAN_TYPES");
      };
      const allLoanRepaymentMethods = async () => {
        return await this.$store.dispatch("GET_ALL_LOAN_REPAYMENT_METHODS");
      };
      const allDistributionChannel = async () => {
        return await this.$store.dispatch("GET_ALL_DISTRIBUTION_CHANNELS");
      };

      return {
        allDisbursement: await allDisbursement(),
        allLoanStatus: await allLoanStatus(),
        allLoanTypes: await allLoanTypes(),
        allLoanRepaymentMethods: await allLoanRepaymentMethods(),
        allDistributionChannel: await allDistributionChannel(),
      };
    },
    async submit() {
      this.form.loan_amount = Number(this.form.loan_amount);
      this.form.interest_rate = Number(this.form.interest_rate);
      this.form.tenor = Number(this.form.tenor);
      this.form.distribution_date = moment(this.form.distribution_date).format(
        "YYYY-MM-DDTHH:mm:ssZ"
      );
      this.form.date_of_incorporation = moment(this.form.date_of_incorporation).format(
        "YYYY-MM-DDTHH:mm:ssZ")
      this.form.dob = moment(this.form.dob).format("DD-MM-YYYY");
      this.form.businessId = this.$route.params.id;
      this.isLoading = true;

      const newForm = this.form

      if (this.form.is_business_registered === false) {
        delete newForm.rc_number
        delete newForm.date_of_incorporation
        delete newForm.bvn
      }

      const response = await this.$store.dispatch(
        "MAKE_LOAN_TRACKER_TWO",
        newForm
      );

      if (response.status === true) {
        this.$router.push({ name: "VFDLoanDashboard" });
      } else {
        prompt("Error", response.message);
        this.isLoading = false;
      }

      this.isLoading = false;
    },
  },
  async mounted() {
    this.loading = true;
    const response = await this.makeRequest();
    this.disbursementOption = response.allDisbursement.data.item.map(
      ({ disbursement_method, _id }) => {
        return {
          label: disbursement_method,
          value: _id,
        };
      }
    );
    this.loanStatusOption = response.allLoanStatus.data.item.map(
      ({ status, _id }) => {
        return {
          label: status,
          value: _id,
        };
      }
    );
    this.loanTypeOption = response.allLoanTypes.data.item.map(
      ({ loan_type, _id }) => {
        return {
          label: loan_type,
          value: _id,
        };
      }
    );
    this.loanRepaymentOption = response.allLoanRepaymentMethods.data.item.map(
      ({ repayment_method, _id }) => {
        return {
          label: repayment_method,
          value: _id,
        };
      }
    );

    this.distributionChannelOption =
      response.allDistributionChannel.data.item.map(
        ({ distribution_channel, _id }) => {
          return {
            label: distribution_channel,
            value: distribution_channel,
          };
        }
      );

    const storeData = GET_USER_DATA()
      .stores.map(({ name, id }) => {
        return {
          label: name,
          value: id,
        };
      })
      .sort((a, b) => a.label.localeCompare(b.label));

    this.loanData = this.loanData.map((data) => {
      if (data.type === "select") {
        if (data.payload === "disbursement_method_id") {
          data.option = this.disbursementOption;
        }
        if (data.payload === "loan_status_id") {
          data.option = this.loanStatusOption;
        }
        if (data.payload === "loan_type_id") {
          data.option = this.loanTypeOption;
        }
        if (data.payload === "repayment_method_id") {
          data.option = this.loanRepaymentOption;
        }
        if (data.payload === "store_id") {
          data.option = storeData;
        }
        if (data.payload === "distribution_channel") {
          data.option = this.distributionChannelOption;
        }
      }
      return data;
    });

    this.loading = false;
  },
};
</script>

<style>
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
</style>